/* You can add global styles to this file, and also import other style files */

@font-face {
    font-family: 'Murecho-Black', ;
    src: url('./assets/fonts/Murecho-Black.ttf');
}

@font-face {
    font-family: 'Murecho-Bold';
    src: url('./assets/fonts/Murecho-Bold.ttf');
}

@font-face {
    font-family: 'Murecho-ExtraBold';
    src: url('./assets/fonts/Murecho-ExtraBold.ttf');
}

@font-face {
    font-family: 'Murecho-ExtraLight';
    src: url('./assets/fonts/Murecho-ExtraLight.ttf');
}

@font-face {
    font-family: 'Murecho-Light';
    src: url('./assets/fonts/Murecho-Light.ttf');
}

@font-face {
    font-family: 'Murecho-Medium';
    src: url('./assets/fonts/Murecho-Medium.ttf');
}

@font-face {
    font-family: 'Murecho-Regular';
    src: url('./assets/fonts/Murecho-Regular.ttf');
}

@font-face {
    font-family: 'Murecho-SemiBold';
    src: url('./assets/fonts/Murecho-SemiBold.ttf');
}

@font-face {
    font-family: 'Murecho-Thin';
    src: url('./assets/fonts/Murecho-Thin.ttf');
}

html,
body,
div,
span,
applet,
object,
iframe,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    font-family: 'Murecho-Medium', sans-serif;
}


/* HTML5 display-role reset for older browsers */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block;
}

body {
    line-height: 1;
}

ol,
ul {
    list-style: none;
}

blockquote,
q {
    quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
    content: '';
    content: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

body{
  height: auto;
  overflow-y: scroll;
  overflow-x: hidden;
}

.znv-chat {
    width: 100px;
    height: 100px;
    position: fixed;
    right: 40px;
    bottom: 75px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    white-space:pre;
    -webkit-transition: 0.3s;
    -ms-transition: 0.3s;
    transition: 0.3s;
}
#znv-chat-auto-0:hover {
    cursor: pointer;
}
.znv-chat::before {
    content: "";
    background: url(./assets/img/frase-chat.png) no-repeat 30px 12px;
    width: 215px;
    height: 75px;
    position: fixed;
    bottom: 100px;
    right: 140px;
    background-color: #f5f5f5;
    padding: 15px;
    border-radius: 10px;
    -webkit-box-shadow: 2px 2px 5px 0px rgb(194 194 194);
    -moz-box-shadow: 2px 2px 5px 0px rgba(194,194,194,1);
    box-shadow: 2px 2px 5px 0px rgb(194 194 194);
}
.znv-chat::after {
    content: '';
    right: 85px;
    top: 60px;
    position: absolute;
    width: 0;
    height: 0;
    box-sizing: border-box;
    border: 8px solid black;
    border-color: transparent transparent #f5f5f5 #f5f5f5;
    transform-origin: 0 0;
    transform: rotate(225deg);
    box-shadow: -3px 3px 3px 0px rgb(194 194 194);
}
.znv-chat .znv-float-button {
    background: url(./assets/img/logo-chat.png) 0 no-repeat !important;
    background-size: contain !important;
    width: 72px !important;
    height: 94px !important;
    border: 0 !important;
    border-radius: 0 !important;
    bottom: initial !important;
    right: initial !important;
}
.znv-is-active {
    right: 470px;
}
.znv-is-active::before {
    right: 570px;
}

.comunicadoMelhoria .swal2-popup.swal2-modal.swal2-show {
    width: 800px;
}
.comunicadoMelhoria .swal2-title {
    color: #88D600;
}
.comunicadoMelhoria .linePopup:not(:last-child) {
    border-bottom: 1px solid #ccc;
    padding-bottom: 20px;
}

.fakeSelect {
    line-height: 35px;
    text-overflow: ellipsis;
    word-wrap: break-word;
    overflow: hidden;
}
.quadroSelectApi {
    position: absolute;
    z-index: 9;
}
.quadroSelectApi .sem-result-select-api {
    font-size: .8rem;
}
.searchSelectApi {
    position: relative;   
}
.searchSelectApi .inputSearch {
    position: relative;
    height: 50px;
    width: 350px;
    font-size: .8rem;
    box-shadow: 5px 5px 10px 1px #a3a3a3;
    border: 1px solid #cfcfcf;
    border-radius: 0;
}
.searchSelectApi .inputSearch:focus-visible {
    outline: none;
}
.searchSelectApi i {
    position: absolute;
    z-index: 9;
    top: 18px;
    left: 15px;
}
.searchSelectApi input {
    padding-left: 40px;
}
.selectApi {
    position: absolute;
    z-index: 9;
    box-shadow: 5px 5px 10px 1px #a3a3a3;
    width: 350px;
    cursor: pointer;
    height: 150px;
    overflow-y: scroll;
    overflow-x: hidden;
}
.selectApi .titleList {
    font-weight: 600;
}
.selectApi .subtitleList {
    font-size: .8rem;
    margin: 0 0 0 20px;
}
.selectApi .valueList {
    font-size: .9rem;
    margin: 5px 0 20px 20px;
}
.selectApi .list-group-item {
    border-radius: 0;
}
.selectApi::-webkit-scrollbar {
    width: 10px;
    background-color: #fff;
}
.selectApi::-webkit-scrollbar-thumb {
    background: #88D600;
}
.selectApi::-webkit-scrollbar-thumb:hover {
    background: #7dc500; 
}
.dominioEmail {
    box-shadow: 5px 5px 10px 1px #a3a3a3;
    border: 1px solid #88D600;
    width: 350px;
    border-radius: 0.25rem;
    position: relative;
}
.dominioEmail input {
    width: 225px !important;
    border: none !important;
    box-shadow: none !important;
}
.dominioEmail span {
    position: absolute;
    top: 0;
    right: 0;
    background: #ccc;
    padding: 0 10px;
    height: 100%;
    line-height: 44px;
    border-radius: 0px 4px 4px 0px;
}

.modalAceiteTermo {
    width: 70em !important;
}
.modalAceiteTermo p {
    text-align: justify;
}

.modalMensagemFinanceiro {
    background-size: cover !important;
    border-bottom-right-radius: 20px !important;
}

.modalMensagemFinanceiro .swal2-styled.swal2-confirm {
    height: 26px;
    padding: 0 14px;
    margin-top: 1rem;
}